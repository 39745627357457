@import 'variables';

abbr {
  border: none!important;
  text-decoration: none!important;
}

@include media-breakpoint-up(lg) {
  .dropdown-menu {
    padding-top: 0px;
    padding-bottom: 0px;
  }
}

.divider {
  margin: 0!important;
}

.fixed-table-pagination {
  padding: 0 15px 0 15px;
}

.margin-top-5 {
  margin-top: 5px;
}

.nested-fields.nested-fields-with-remove-link {
  position: relative;
  background: #fafafa;
  padding: 5px 24px 5px 5px;
  border-radius: 4px;
  margin-bottom: 15px;
  textarea {
    resize: vertical;
  }
  .remove_fields {
    line-height: 1;
    position: absolute;
    right: 0;
    top: 0;
    width: 20px;
    text-align: center;
    padding: 1px 3px;
    display: block!important;
    background: #fff;
    border: 1px solid #F7F7F7;
    border-radius: 0 4px 0 4px;
  }
}

textarea{
  resize: vertical;
}

@for $i from 0 through 12{
  .margin-top-#{$i * 5}{
    margin-top: #{$i * 5}px !important;
  }
}

.strong{
  font-weight: bold;
}

.smaller{
  font-size: 70%;
}

.checkbox-fix input[type=checkbox] {
  width: auto;
}

div.alwaysbreak {
  page-break-before: always;
}

div.nobreak:before {
  clear:both !important;
}

div.nobreak {
  page-break-inside: avoid !important;
}

.reference {
  .description {
    textarea{
      max-height: 120px !important;
    }
  }
  .link {
    textarea{
      max-height: 30px !important;
    }
  }

}

.application-member-table {
  .table {
    width: 96%;
    max-width: 96%;
    margin-left: 10px;
    margin-right: 10px;
  }
}

.margin-top-3 {
  margin-top: 3px !important;
}
.margin-top-5 {
  margin-top: 5px !important;
}
.margin-top-10 {
  margin-top: 10px !important;
}
.margin-top-30 {
  margin-top: 30px !important;
}

.margin-right-3 {
  margin-right: 3px !important;
}
.margin-right-4 {
  margin-right: 4px !important;
}
.margin-right-5 {
  margin-right: 5px !important;
}
.margin-left-5 {
  margin-left: 5px !important;
}

.margin-bottom-5 {
  margin-bottom: 5px !important;
}
.margin-bottom-35 {
  margin-bottom: 35px !important;
}

.text-decoration-none {
  text-decoration: none !important;
}
