.application-data-columns {
  .form-group {
    &.check_boxes {
      .checkbox {
        margin-right: 8px;
        input {
          margin: 0px 5px 0px 0px;
        }
      }

      label {
        margin-left: 0px;
        margin-right: 10px;
        font-size: .80rem;
      }
    }
  }
}

.application-identifiers {
  .form-group {
    &.check_boxes {
      .checkbox {
        margin-right: 8px;
        input {
          margin: 0px 5px 0px 0px;
        }
      }

      label {
        margin-left: 0px;
        margin-right: 10px;
      }
    }
  }
}
