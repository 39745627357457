@import 'variables';

.card-body {
  input {
    margin-right: 10px;
    margin-bottom: 10px;
  }
  textarea {
    margin-right: 10px;
    margin-bottom: 10px;
  }
  .radio, .checkbox {
    font-weight: normal;
    margin-top: 10px;
    margin-bottom: 5px;
    input {
      margin-top: 10px;
      margin-right: 10px;
    }
  }
}



//.btn-file {
//  position: relative;
//  overflow: hidden;
//
//  input[type=file] {
//      position: absolute;
//      top: 0;
//      right: 0;
//      min-width: 100%;
//      min-height: 100%;
//      font-size: 100px;
//      text-align: right;
//      filter: alpha(opacity=0);
//      opacity: 0;
//      outline: none;
//      background: white;
//      cursor: inherit;
//      display: block;
//  }
//}
//
//.hint {
//  font-size: 11px;
//  margin-top: 8px;
//}
//
//.help-block {
//  font-size: 70%;
//}
//
//.download-panel {
//  margin-top: 10px;
//}
//
//.file-download{
//  margin-right: 10px;
//  &:hover {
//    text-decoration: none;
//    color: darken($brand-primary, 20%)
//  }
//  &::before{
//    content: "\f019";
//    margin-right: 5px;
//    font-size: 90%;
//    font-family: FontAwesome;
//    color: $brand-primary;
//  }
//}
//
//.title-toggle {
//  cursor: pointer;
//  &:before{
//    vertical-align: middle;
//    content: '\f146';
//    font-family: FontAwesome;
//    font-size: 80%;
//    margin-right: 5px;
//    color: grey;
//  }
//  &.collapsed{
//    &:before{
//      vertical-align: middle;
//      content: '\f0fe';
//      font-family: FontAwesome;
//      font-size: 80%;
//      margin-right: 5px;
//    }
//  }
//}
//
//.research {
//  min-height: 200px !important;
//}
//
//.data_dictionary_tooltip {
//  text-align: left;
//  background-color: #ffffff;
//}
//
//.tooltip-inner {
//  text-align: left;
//}
