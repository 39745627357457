@import 'variables';

.main-logo {
  @include media-breakpoint-down(md) {
    width: 200px;
  }
}

.navbar {
  position: relative;
  top: 100%;
  height: calc(100vh - 100%) !important;
  width: 100%;
  left: 0%;
  transition: left 0.35s;
  padding: 1rem;
  background: lighten($brand-primary, 45%);
}

.navbar-nav li {
  a {
    font-size: 17px;
    color: #2c689c !important;
    text-decoration: none;
  }
}

.applications {
  font-size: 80%;
}

.top-menu {
  background-color: lighten($brand-primary, 45%);
  z-index: 1000;
  position: fixed;
  top: 3px;
  right: 5px;
  font-size: 70%;
  color: $brand-primary;
  padding: 5px;
  @include media-breakpoint-down(sm) {
    position: fixed;
    bottom: 3px;
    right: 5px;
    display: none;
  }

  a {
    &.active {
      color: darken($brand-primary, 20%)
    }

    &:hover {
      color: darken($brand-primary, 20%);
      text-decoration: none;
    }

    &::before {
      margin-right: 5px;
      text-decoration: none;
    }
  }
}

//.submenu {
//  li {
//    margin: 5px;
//    margin-left: 0px;
//    @include media-breakpoint-down(md) {
//      margin-top: 3px;
//      margin-bottom: 3px;
//    }
//
//    a {
//      font-size: 15px;
//      @include media-breakpoint-down(md) {
//        font-size: 14px;
//      }
//
//      &:hover {
//        text-decoration: none;
//      }
//
//      &::before {
//        margin-right: 5px;
//        font-size: 90%;
//        font-family: FontAwesome;
//        color: $brand-primary;
//      }
//
//      &.active::before {
//        color: #0265a3 !important;
//        content: "\f058";
//      }
//    }
//  }
//}
//
//.alert {
//  margin: 0;
//}
