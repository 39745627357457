@import 'variables';

a.cap {
  color: white !important;

  &:hover {
    text-decoration: none;
  }
}

a {
  color: #2c689c;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

.h5 {
  margin-bottom: 5px;
  font-weight: bolder;
  margin-top: 20px;
  font-size: 120%;
}

.lead {
  margin-top: 10px;
  margin-bottom: 5px;
  font-size: 24px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

.card-header {
  font-size: 20px;
}

.container-xxl {
  max-width: 93% !important;
}