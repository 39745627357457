@import 'variables';

tr {
  page-break-inside: avoid;
}

.status-update {
  .date-width {
    width: 24% !important;
    margin: 0px !important;
  }
}


